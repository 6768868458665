import React from 'react'
import { TextField,Paper, FormControlLabel,Switch,IconButton,Divider } from '@material-ui/core'
import IconDelete from '@material-ui/icons/Delete'
import IconUp from '@material-ui/icons/VerticalAlignTop';
import IconDown from '@material-ui/icons/VerticalAlignBottom';
export default function Textblock({item,onChange,onDelete,onChangeSort}) {
    const changeItem = (content,attribute)=>{ 
        let newItem = Object.assign({},item);
        newItem[attribute] = content;
        onChange(newItem);
    }
    return (
        <Paper className="padding" style={{marginBottom:'6px'}}>
           <TextField fullWidth  placeholder="Titel" value={item.textTitle || ''} onChange={(ev)=>{changeItem(ev.target.value,'textTitle')}} ></TextField>
            <TextField fullWidth multiline placeholder="(Introductie) tekst" value={item.text || ''} onChange={(ev)=>{changeItem(ev.target.value,'text')}} ></TextField>
            <br/><br/><Divider />
            <IconButton onClick={()=>{onChangeSort(item,'up')}}><IconUp/></IconButton>
            <IconButton  onClick={()=>{onChangeSort(item,'down')}}><IconDown/></IconButton>
            <div style={{ float: 'right' }}>
                {onDelete && <IconButton onClick={onDelete}><IconDelete /></IconButton>}
                </div>
            <br style={{clear:'both'}}/>
         </Paper>
    )
}
