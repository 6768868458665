/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState, useRef } from 'react'
import { Context } from '../../AppContext';
import { ContextUI } from '../../uiContext';
import LevelElements from '../index/levelelements';
import { Typography } from '../ui/typography';

import { List, ListItem, ListItemText, ListItemIcon, Menu, MenuItem, IconButton, Button, Dialog } from '../ui/core'
import { MoreVert, InsertDriveFile as FileIcon } from '../ui/coreIcons';
import ServiceGeneral from '../../services/general';
import AppSettings from '../../AppSettings';
import CssHTMLBuilderBlock from '../admin/htmlbuilder/block.css'
import ServiceFile from '../../services/file';
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
export default function Index(props) {
    const contextUI = useContext(ContextUI);
    const context = useContext(Context);
    const [listLevels, setListLevels] = useState([]);
    const contentRef = useRef();
    const [hasHtml, setHasHtml] = useState(false);
    const [title, setTitle] = useState("");
    const [showLoginButton, setShowLoginButton] = useState(false);
    const [revision, setRevision] = useState();
    const [showRevision, setShowRevision] = useState(false)

    useEffect(() => {
        if (props.match.params.id) {
            context.setCurrentLevel(props.match.params.id);
            let found = context.info.Index.find(index => { return index.Name === 'revisie.json' && index.HPath === props.match.params.id })
            if (found) {
                ServiceFile.getContent(found.DPath)
                    .then(result => {
                        if (result.changes && result.changes.length > 0) {
                            if(getLastRevisionDate(result).length>0){
                                setRevision(result);
                            }                          
                        }
                    })
            } 
        } else {
            context.setCurrentLevel('');
        }
    }, [props.match.params.id])

    const getLastRevisionDate = (revision) => {
        let lastDate;
        let version;
        if (revision.changes) { 
            for (let i = 0; i < revision.changes.length; i++) {
                 if (revision.changes[i].date) {
                    if (lastDate === undefined && new Date(revision.changes[i].date)) {
                        lastDate =  new Date(revision.changes[i].date);
                        version = revision.changes[i].version;
                    }else{
                        if (lastDate < new Date(revision.changes[i].date)){
                       
                            lastDate = new Date(revision.changes[i].date);
                            version = revision.changes[i].version;
                        }
                    }
                }

            }
        }
        if (lastDate) {
            
            return (version?" - v."+version:"") +" - " + ServiceGeneral.formatDate(lastDate, false);
        } else {
            return "";
        }

    }

    const getPathName = (levelName) => {
        try {
            const levelNameSplit = levelName.split('/');
            levelName = levelNameSplit[levelNameSplit.length - 2];
            if (context.info.Settings && context.info.Settings.hideLevelNumbers) {
                try {
                    let prefix = levelName.split(' ')[0];
                    if (!isNaN(prefix)) {
                        levelName = levelName.replace(prefix + ' ', '');
                    }
                } catch (ex) { }
            }
        } catch (ex) { }
        return levelName;
    }

    useEffect(() => {
        contextUI.setSubTitle('');
        if (context.info) {
            if (context.info.Index.length === 0) {
                // props.history.push('/settings');
                props.history.push('/settings?redirect=' + props.location.pathname)
            } else {
                context.info.Settings && contextUI.setTitle(context.info.Settings.Title || 'Handboek App')
                let tmpLevels = [];
                if (context.currentLevel === '') { //highest level 

                    contextUI.setShowBackButton(false);
                    tmpLevels = context.info.Levels.map(level => {
                        if (level.Path.split('/').length === 2) {
                            let retVal = [];
                            if (level.Path !== '/') {


                                retVal.push(<Typography key={'h1' + level.HPath} className="padding" component="h1">{getPathName(level.Path)}</Typography>);

                                let foundLevel = context.info.Levels.find((l) => { return l.HPath === level.HPath });

                                retVal.push(<LevelElements key={'le' + level.HPath} dirInfo={foundLevel ? foundLevel.DirInfo : null} currentPath={level.Path} currentHPath={level.HPath} levels={context.info.Levels} index={context.info.Index} {...props}></LevelElements>)

                            }
                            return retVal;
                        } else {
                            return null;
                        }
                    })
                } else { //in a level 
                    if (window.history.length > 1) {
                        contextUI.setShowBackButton(true);
                    } else {
                        contextUI.setShowBackButton(false);
                    }
                    let foundLevel = context.info.Levels.find((level) => { return level.HPath === context.currentLevel });
                    if (foundLevel) {
                        const pathSplit = foundLevel.Path.split('/');
                        let title = pathSplit[pathSplit.length - 2];

                        if (context.info.Settings && context.info.Settings.hideLevelNumbers) {
                            try {
                                let prefix = title.split(' ')[0];
                                if (!isNaN(prefix)) {
                                    title = title.replace(prefix + ' ', '');
                                }
                            } catch (ex) { }
                        }
                        contextUI.setTitle(title);
                        setTitle(title);
                        if (context.info.Index.filter(f => { return f.HPath === context.currentLevel && f.Extention === 'html' }).length > 0) {
                            setHasHtml(true)
                        } else {
                            setHasHtml(false)
                        }
                        tmpLevels.push(<div key="path" style={{ color: 'gray', fontSize: '10px', width: '100%', color: 'gray' }}><div style={{ padding: '6px' }}>/{foundLevel.Path}</div></div>)
                        if (foundLevel) {
                            tmpLevels.push(<LevelElements key={foundLevel.HPath} dirInfo={foundLevel.DirInfo} currentPath={foundLevel.Path} currentHPath={foundLevel.HPath} levels={context.info.Levels} index={context.info.Index} {...props}></LevelElements>)
                        }
                    } else {
                        //level not found
                        setShowLoginButton(true);
                    }
                }
                setListLevels(tmpLevels);
            }
        }
    }, [context.info, context.currentLevel])

    const getExportHtml = (html) => {
        return `
        <!DOCTYPE html>
        <html>
        <head>
        <meta charset="UTF-8">
        </head>
        <body>   
        <style>
        *{
            font-size:11px;
            font-family:Roboto,Helvetica Neue,sans-serif;
        } 
        body{
    padding: 15px;            
        }

.block{
  
    padding-left:8px;
    padding-right:8px;
    margin-left:4px;
    margin-right:4px;
    
    border-left: 2px solid silver;
    margin-bottom: 4px;
}
.block-table td{
  padding : 4px;
}
.block-header{
  color:black;
  cursor: pointer;
  margin-bottom: 3px;
  min-height: 50px;
  border-top:1px solid #d2d2d2;
}
.page-menu{

  padding-left:8px;
  padding-right:8px;
  padding-top:5px;
  margin-left:4px;
  margin-right:12px;
  margin-bottom: 8px;
  margin-top: 12px;
}
.block-menu{
    margin:8px;
    
    text-align: center;
    border-top: 3px solid black;
    background-color:white;
    box-shadow: 2px 2px 5px 2px silver;
}
.block-add-box{
    margin:4px;
   display: inline-block;
   vertical-align: middle;
    width:90px;
    height: 80px;
    text-align: center;
    font-size: 11px;
    cursor: pointer;
    color:gray;
}
.block-add-box:hover{
  color:black;
}
.block-add-box img{
  width:40px;
  height:40px;
    
}

.block-container{
  width:100%;
  height: 100%;
  display: flex;
}
.block-settings{
  width:400px;
  height: 100%;
  overflow-y: auto;
  background-color: #dcdcdc;
}

.block-content{
  padding:8px;
  height: 100%;
  width:100%;
  overflow-y: auto;
}

.htmlbuilder-paragraph-card{
    text-align: center;
    background-color: #f3f3f3;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    border-radius: 4px 4px 4px 4px;
    padding:4px;
    margin:8px;
    width:312px;
  }
  
  .htmlbuilder-paragraph-card-image{
    margin:6px;
    width:300px;
    cursor:pointer;
  } 

  .htmlbuilder-paragraph-card-description{
    font-style: italic;
    color:#5a5a5a;
  }
  .htmlbuilder-gallery-container{
    display:flex;
    flex-wrap: wrap;
  }
  .htmlbuilder-gallery-card{
    
    text-align: center;
    background-color: #f3f3f3;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    border-radius: 4px 4px 4px 4px;
    padding:4px;
    margin:4px;
    width:100%;
  }
  .htmlbuilder-gallery-card-image{
    margin:8px;
    width:90%;
  }
  .htmlbuilder-gallery-card-description{
    font-style: italic;
    color:#5a5a5a;
  }  


  .htmlbuilder-paragraph-card-image-magnify{
      display:none;
  }
        </style>   
        ${html}
        </body>
        </html>`
    }

    const downloadPDF = () => {

        ServiceGeneral.postForm('https://pdf.vedebo.net/', { filename: title + '.pdf', html: getExportHtml(contentRef.current.innerHTML) });
    }

    const hasFeedback = () => {

        if (!context.info.LoggedIn && context.info.Settings.disablepublicfeedback === true) return false;
        if (context.info.LoggedIn && context.info.Settings.disableprivatefeedback === true) return false;

        return true;
    }
    const redirectLogin = () => {
        props.history.push('/settings?redirect=' + props.location.pathname)
    }
    return (
        <>
            {showLoginButton && <><br /><Button fullWidth style={{ boxSizing: 'border-box', display: 'block' }} onClick={redirectLogin}>Klik hier om in te loggen</Button>  </>}
            {showRevision && <Dialog>
                <DialogTitle><Typography>Revisie</Typography></DialogTitle>
                <DialogContent>
                    {revision.name && <> Contactpersoon: {revision.name} ({revision.email})<br /><br /></>}
                    <table style={{ width: '100%' }}><tbody>
                        <tr><th>versie</th><th>datum</th><th >omschrijving</th></tr>
                        {revision.changes && revision.changes.map(rev => {
                            return <tr key={rev.uuid}><td>{rev.version}</td><td >{ServiceGeneral.formatDate(rev.date)}</td><td style={{whiteSpace:'pre'}}>{rev.remark}</td></tr>
                        })}
                    </tbody></table>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setShowRevision(false) }}>Sluiten</Button>
                </DialogActions>
            </Dialog>}
            {hasHtml && AppSettings.getPlatform() === 'web' && <div style={{ float: 'right', paddingRight: '10px', paddingTop: '8px' }}>   <Menu id={"menu_downloadHtml"} control={<IconButton><MoreVert /></IconButton>}>
                <MenuItem onClick={downloadPDF}>  Download PDF </MenuItem>
            </Menu></div>}
            <div ref={contentRef}>
                {listLevels}
                <br /><br />
            </div>

            {hasFeedback() && <Button onClick={() => { props.history.push('/feedback') }} variant="text" style={{ float: 'right', fontSize: 'small' }}>Geef feedback</Button>}
            {revision && <Button onClick={() => { setShowRevision(true) }} variant="text" style={{ float: 'right', fontSize: 'small' }}>Revisie {getLastRevisionDate(revision)}</Button>}
            <br style={{clear:'both'}}/><br/><br/><br/><br/><br/>
        </>
    )
}
