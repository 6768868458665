/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react'
import FileService from '../../services/file';
import StorageService from '../../services/storage';
import XLSX from '../../services-admin/xlsx';
import AddressbookContact from './addressbookcontact';
import { Context } from '../../AppContext';
import { Button } from '../ui/button';
import { Menu } from '../ui/menu';
import { MenuItem } from '../ui/menuItem';
import { IconButton } from '../ui/iconButton';
import { MoreVert } from '../ui/coreIcons';
export default function Addressbook(props) {
    const context = useContext(Context);
    const [contacts, setContacts] = useState([]);
    //props.dirInfo
    useEffect(() => {
        if (props.id) {
            //   readStorage(); 
            FileService.getContent(props.file.DPath, StorageService.getStorageFileName(props.file), true)
                .then(content => {
                    setContacts((typeof content === 'string') ? JSON.parse(content) : content);
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }, [props.id]) 

    const downloadExcel = () => {   
        let tmpAdresboek = Object.assign([],contacts);
        tmpAdresboek  = tmpAdresboek.map((contact)=>{delete contact.Image;delete contact._checked;delete contact.ID; return contact;})
        XLSX.downloadJSON(tmpAdresboek,"adresboek.xlsx");
    }
    /*
    useEffect(()=>{
        if(context.decryptedFiles && context.decryptedFiles[StorageService.getStorageFileName(props.file) ]){
            try{
                setContacts(JSON.parse(FileService.decodeUnicode(context.decryptedFiles[StorageService.getStorageFileName(props.file) ])));
 
            }catch(ex){
                console.log(ex)
            }
        }   
     },[context.decryptedFiles])

    const readOnline = () => {
        FileService.getContentOnline(props.id).then((result) => {
            setContacts(result);
        }, (err) => { console.error(err) })
    }

    const readStorage = () => {
        StorageService.readFile(StorageService.documentsDir(), StorageService.getStorageFileName(props.file) )
            .then((result) => {
                try {
                    context.decryptFile( StorageService.getStorageFileName(props.file) );
                } catch (ex) {
                    readOnline();
                }
            }, (err) => {
                readOnline();
            })
    }
*/
    return (
        <div>
            {props.dirInfo && props.dirInfo.downloadAddressbook ===true && <div style={{ float: 'right', paddingRight: '10px', paddingTop: '8px' }}>   <Menu id={"menu_downloadHtml"} control={<IconButton><MoreVert /></IconButton>}>
                <MenuItem onClick={downloadExcel}>  Download adresboek in Excel </MenuItem>
            </Menu></div>}
            {contacts.map((contact, i) => {
                return <AddressbookContact key={i} contact={contact} />
            })}
        </div>
    )
}
