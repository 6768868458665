/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react'
import Announcements from '../components/announcements/announcements'
import Announcement from '../components/announcements/announcement';
import {ContextUI} from '../uiContext';
import { Context } from "../AppContext";
import { Slide } from '@material-ui/core';
export default function Messages(props) {
    const contextUI = useContext(ContextUI); 

    const context = useContext(Context);
    const [announcement, setAnnouncement] = useState(null);

    useEffect(() => {
        context.checkAnnouncements();

    }, [])

    useEffect(() => {
        if (props.match.params.id && context.announcements) {
            let found = context.announcements.find((item) => { return item._id === props.match.params.id });
            setAnnouncement(found);
            contextUI.setShowBackButton(true);
            contextUI.setTitle('Mededeling');
            contextUI.setSubTitle('');
        } else {
            contextUI.setShowBackButton(false);
            contextUI.setTitle('Mededelingen');
            contextUI.setSubTitle('');
        }
    }, [props.match.params.id, context.announcements])


    return (
        <React.Fragment>
            <Slide direction='left' in={true} mountOnEnter unmountOnExit>
                <div>
                    {announcement
                        ? <Announcement announcement={announcement} onClose={() => { props.history.push('/announcements') }} />
                        : (context.announcements && <Announcements announcements={context.announcements}  {...props} />)}
                </div></Slide>
        </React.Fragment>
    )
}
