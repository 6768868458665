import React from 'react'
import { ListItem, ListItemSecondaryAction, IconButton, ListItemText } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';
import AttachmentIcon from '@material-ui/icons/AttachFile';
import AppSettings from '../../../AppSettings';

export default function attachment({ item, onDelete }) {
    return (
        <ListItem dense key={item} onClick={() => window.open(AppSettings.getLocationDocumentAPI() + 'download?key=' + item.split('/')[0])}>
            <IconButton aria-label="Downloaden" >
                <AttachmentIcon />
            </IconButton>
            <ListItemText
                primary={item.split('/')[1]}
            />
            {onDelete && <ListItemSecondaryAction>
                <IconButton aria-label="Delete" onClick={() => onDelete(item)}>
                    <DeleteIcon />
                </IconButton>

            </ListItemSecondaryAction>}
        </ListItem>
    )
}
