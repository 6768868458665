import React, { useEffect, useState } from 'react'
import { TextField, Paper, Checkbox, LinearProgress, List, ListItem, ListItemSecondaryAction, IconButton, ListItemText } from '@material-ui/core'

import FileService from '../../../services/file';
import Attachment from '../view/attachment';
export default function UploadFile({ item, id, onChange }) {
    const [progress, setPercentage] = useState(0);
    const [error, setError] = useState('');

    const changeItem = (content, attribute) => {
        let newItem = Object.assign({}, item);
        if (!newItem[attribute]) { newItem[attribute] = [] }
        if (newItem[attribute].indexOf(content) >= 0) {
            newItem[attribute].splice(newItem[attribute].indexOf(content), 1)
        } else {
            newItem[attribute].push(content);
        }
        onChange(newItem);
    }

    let uploadFile = (evt) => {
        if (document.getElementById(id).files[0]) {
            let foundFile = document.getElementById(id).files[0];
           
            setPercentage(1);
            FileService.uploadFile(foundFile, null, setPercentage)
                .then(
                    (key) => {
                        changeItem(key, 'answers');
                        document.getElementById(id).value = '';
                        setPercentage(0);
                    },
                    (error) => {
                        setError('Uw document kan niet worden verzonden naar de server. Controleer uw internetverbinding of probeer het op een later moment opnieuw.')
                        document.getElementById(id).value = '';
                        setPercentage(0);
                    })
        }
    }

    return (
        <Paper className="padding" style={{ marginBottom: '6px' }}>
            <span className="questionline">{item.question}</span>{item.required && <span className="required">*</span>} <br /><br />
            {progress !== 0
                &&
                <LinearProgress variant="determinate" value={progress} />
            }
            <div className="errorline">{error}</div>
            <input type="file" name={id} id={id} onChange={(e) => uploadFile(e)} />
            <List>
                {item.answers && item.answers.map((item) => {
                    return <Attachment key={item} item={item} onDelete={delItem => changeItem(delItem, 'answers')} />
                })
                }
            </List>
        </Paper>
    )
}
