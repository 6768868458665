import React, { useState, useEffect } from 'react'
import { Dialog, DialogContent, DialogTitle, Typography, Tabs, IconButton } from '../ui/core'
import FillInForm from './fillinform';
import FormsView from './view/answers';
import { Close } from '../ui/coreIcons';
import ServiceForms from '../../services/form';
export default function FormPopup({ form, onClose }) {
    const [selectedTab, setSelectedTab] = useState(0);
    const [sendForms, setSendForms] = useState([]);
    const [answer, setAnswer] = useState(null);
    useEffect(() => {
        if (selectedTab == 1) {
            ServiceForms.get(form.id)
                .then(forms => {
                    setSendForms(forms);
                })
        }
    }, [selectedTab])

    const editAnswer = (answer) => {
        setAnswer(answer);
        setSelectedTab(0);
    }
    return (
        <Dialog
            open={true}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            fullScreen
            className="padding-ios"
        >
            <DialogTitle id="alert-dialog-title" className="padding-ios" >
                <div className="padding-ios">
                    <table><tbody><tr>
                        <td>  <IconButton onClick={onClose} style={{ marginTop: '8px' }}><Close /></IconButton>
                        </td>
                        <td>
                            <Typography component="h2">{form.title}</Typography>
                        </td>
                    </tr></tbody></table>
                    {<Tabs value={selectedTab} onChange={(index) => { setSelectedTab(index) }} aria-label="menu">
                        <div className="ui-tab" label="Nieuw formulier" >FORMULIER</div>
                        <div className="ui-tab" label="Overzicht formulieren" >CONCEPT / VERSTUURD</div>
                    </Tabs>}
                </div>
            </DialogTitle>
            <DialogContent >
                {selectedTab === 0 && <FillInForm form={form} onClose={onClose} answer={answer} />}
                {selectedTab === 1 && <FormsView form={form} answers={sendForms} showConcepts={true} onEdit={editAnswer} />}
            </DialogContent>
        </Dialog>

    )
}
