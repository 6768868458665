import React, { useState  } from 'react' 
import VEditor from "../../../veditor/veditor"
import FilesService from "../../../../services-admin/files";


import {Dialog,DialogContent, DialogActions, DialogTitle,Button,Typography} from '../../../ui/core';

export default function EditHtml(props) {
  const [contentRef] = useState(React.createRef());

  const save = () => {
    FilesService.saveBase64(props.version, props.access, props.level, props.file.filename, FilesService.encodeUnicode(contentRef.current.innerHTML))
      .then((result) => {
        props.onRefresh();
        props.onClose();
      }, (err) => {
        props.onRefresh();
        props.onClose();
      })
  }

  return (
    <Dialog fullScreen  onClose={props.onClose}  className="padding-ios">
      <DialogTitle><Typography component="h2">{props.file.filename}</Typography></DialogTitle>
      <DialogContent style={{ padding: '0px' }}>
        <VEditor id="html-edit" value={props.file.content} contentRef={contentRef} files={props.files} pages={props.pages} />
      </DialogContent>
      <DialogActions>
        <Button  variant="outlined" onClick={props.onClose}>Annuleren</Button>
        <Button variant="contained" color="primary" onClick={save}>Opslaan</Button>
      </DialogActions>
    </Dialog>
  )
}
