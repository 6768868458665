import React from 'react';
import { renderToString } from 'react-dom/server'
const Service = {
    removeTags: (str) => {
        var div = document.createElement("div");
        div.innerHTML = str;
        var text = div.textContent || div.innerText || "";
        return text;
    },
    createHTML: (builderFile) => {
        return new Promise((resolve, reject) => {
            try {
                //builderFile.collapseChapter
                let htmlFile = '';
                let blocks = builderFile.blocks.sort((a, b) => { return a.sort - b.sort });

                if (builderFile.showIndex === true) {
                    htmlFile += Service.createHTMLIndex(blocks)
                }
                let defaultStyle = '';
                let lastH1 = '';
                let lastH2 = '';
                for (let b = 0; b < blocks.length; b++) {

                    if (blocks[b].publish === true) {
                        switch (blocks[b].type) {
                            case 'heading':

                                htmlFile += Service.createHTMLBlockHeading(blocks[b], lastH1, defaultStyle);
                                if (blocks[b].headingSize === 'h1') {
                                    lastH1 = blocks[b]._id;
                                    lastH2 = '';
                                }
                                if (blocks[b].headingSize === 'h2') {
                                    lastH2 = blocks[b]._id;
                                }
                                if (blocks[b].collapsedefaultopen === true || blocks[b].collapse !== true) {
                                    defaultStyle = 'display:block'
                                } else {
                                    defaultStyle = 'display:none'
                                }

                                break;
                            case 'paragraph':
                                htmlFile += Service.createHTMLBlockParagraph(blocks[b], lastH1, lastH2, defaultStyle)
                                break;
                            case 'table':
                                htmlFile += Service.createHTMLBlockTable(blocks[b], lastH1, lastH2, defaultStyle)
                                break;
                            case 'paragraphimage':
                                htmlFile += Service.createHTMLBlockParagraphImage(blocks[b], lastH1, lastH2, defaultStyle)
                                break;
                            case 'paragraphyoutube':
                                htmlFile += Service.createHTMLBlockParagraphYoutube(blocks[b], lastH1, lastH2, defaultStyle)
                                break;
                            case 'gallery':
                                htmlFile += Service.createHTMLBlockGallery(blocks[b], lastH1, lastH2, defaultStyle)
                                break;
                        }
                    }

                }
                resolve(htmlFile);
            } catch (ex) {
                console.log(ex)
                reject()
            }

        })
    },
    createHTMLIndex: (blocks) => {
        let htmlFile = '';
        htmlFile = `<h1>Inhoudsopgave</h1>`;
        for (let i = 0; i < blocks.length; i++) {

            if (blocks[i].type === 'heading') {
                let paddingLeft = 0;
                if (blocks[i].headingSize == 'h2') paddingLeft = '15px';
                if (blocks[i].headingSize == 'h3') paddingLeft = '30px';
                htmlFile = htmlFile + `<a class="linkTag" style="padding-left:${paddingLeft}" href="${blocks[i]._id}">${Service.removeTags(blocks[i].heading)}</a><br/>`
            }
        }
      
        htmlFile = htmlFile + '<br/><br/>'
        return htmlFile;
    },
    createHTMLBlockHeading: (block, lastH1, defaultStyle) => {
        let htmlFile = '';
        if (block.headingSize) {
            let className = '';
            if (block.collapse === true) {
                className = 'toggleBlock'
            }
            if (block.collapsedefaultopen !== true && block.collapse === true) {
                className += ' collapsed'
            }
            htmlFile = `<${block.headingSize}  class="${className}" id="${block._id}">${block.heading || ''}</${block.headingSize}>`
        } else {
            htmlFile = `<h1 class="toggleBlock" id="${block._id}">${block.heading || ''}</h1>`
        }
        return `<div class="block-header  style="${defaultStyle || ''}">${htmlFile}</div>`
        // return `<div class="block-header belongsToHeader_${lastH1}"  style="${defaultStyle||''}">${htmlFile}</div>`
    },
    createHTMLBlockParagraph: (block, lastH1, lastH2, defaultStyle) => {
        let htmlFile = '';
        htmlFile = `<div class="belongsToHeader_${lastH1} belongsToHeader_${lastH2} block-content" style="${defaultStyle}">${block.paragraph || ''}</div>`
        return htmlFile
    },
    createHTMLBlockTable: (block, lastH1, lastH2, defaultStyle) => {
        let htmlFile = '';

        let retVal = [];
        for (let r = 0; r < block.maxRows; r++) {
            let tmpCells = [];
            for (let c = 0; c < block.maxColumns; c++) {
                tmpCells.push(<td key={'row' + r + 'cell' + c} style={block.cells[r][c].style}><div dangerouslySetInnerHTML={{ __html: block.cells[r][c].content }}></div></td>);
            }
            retVal.push(<tr key={'row_' + r}>{tmpCells}</tr>)
        }

        let table = <table style={{ width: '100%' }} className="block-table" cellSpacing="0" cellPadding="0"> <tbody>{retVal}</tbody></table>;

        htmlFile = `<div class="belongsToHeader_${lastH1} belongsToHeader_${lastH2} block-content" style="${defaultStyle}">${renderToString(table)}</div>`;

        return htmlFile
    },
    createHTMLBlockParagraphImage: (block, lastH1, lastH2, defaultStyle) => {
        let htmlFile = '';
        // htmlFile = `<div><div ><img style="margin:6px;width:300px;float:${block.alignImage === 'left' ? 'left' : 'right'}" src="${block.image}" /></div>${block.paragraph}<br style="clear:both"/></div>`
        htmlFile = `<div class="belongsToHeader_${lastH1} belongsToHeader_${lastH2} block-content"  style="${defaultStyle}">
            <div class="htmlbuilder-paragraph-card" style="float:${block.alignImage === 'left' ? 'left' : 'right'};margin-right:${block.alignImage === 'left' ? '40px' : 'auto'}" >                
            <div style="position:absolute;margin:10px;pointer-events: none;" class="htmlbuilder-paragraph-card-image-magnify" >
            <svg x="0px" y="0px" width="50px" height="50px" viewBox="0 0 24 24">
             <g id="Icons" style="opacity:0.75;">
                <path id="search-big" d="M18.853,17.438l-3.604-3.604c-0.075-0.075-0.166-0.127-0.267-0.156C15.621,12.781,16,11.686,16,10.5
               C16,7.463,13.537,5,10.5,5S5,7.463,5,10.5S7.463,16,10.5,16c1.186,0,2.281-0.379,3.18-1.018c0.028,0.101,0.08,0.191,0.155,0.267
               l3.604,3.604c0.301,0.301,0.858,0.227,1.249-0.165C19.079,18.297,19.153,17.739,18.853,17.438z M10.5,14C8.568,14,7,12.432,7,10.5
               S8.568,7,10.5,7S14,8.568,14,10.5S12.432,14,10.5,14z" fill="#292929"/></g></svg>
            </div>    
            <img  src="${block.image}"  class="htmlbuilder-paragraph-card-image"  /><br/>

            <div class="htmlbuilder-paragraph-card-description" >${block.imageDescription || ''}</div>
            <br style="clear:both"/></div>${block.paragraph || ''}
            </div>`
        return htmlFile
    },
    createHTMLBlockParagraphYoutube: (block, lastH1, lastH2, defaultStyle) => {
        let htmlFile = '';
        /*htmlFile = `<div class="belongsToHeader_${lastH1} belongsToHeader_${lastH2} block-content" style="${defaultStyle}"><div style="margin:6px;width:300px;float:${block.alignImage === 'left' ? 'left' : 'right'};margin-right:${block.alignImage === 'left' ? '40px' : 'auto'}">
        <iframe width="300" height="240" src="https://www.youtube.com/embed/${block.youtubeCode}" frameborder="0" allow="accelerometer; autoplay;  encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>${block.paragraph ||''}<br style="clear:both"/></div>`
*/
        htmlFile = `<div class="belongsToHeader_${lastH1} belongsToHeader_${lastH2} block-content" style="${defaultStyle}">
       
        <div style="margin:6px;width:320px;height:180px;float:${block.alignImage === 'left' ? 'left' : 'right'};margin-right:${block.alignImage === 'left' ? '40px' : 'auto'};background-image:url(https://img.youtube.com/vi/${block.youtubeCode}/mqdefault.jpg)">
       
        <div style=" box-sizing: border-box;padding:8px;width:100%;margin-top:140px;height:40px;background-color:rgba(255,255,255,0.8);text-align:center">
            <a class="link" href="https://www.youtube.com/watch?v=${block.youtubeCode}" style="text-decoration:none">BEKIJK VIDEO</a>
        </div>
         
      
        </div>${block.paragraph || ''} 
        <br style="clear:both"/></div>`

        //  

        return htmlFile
    },
    createHTMLBlockGallery: (block, lastH1, lastH2, defaultStyle) => {
        let htmlFile = '';
        if (block.images && block.images.length > 0) {
            for (let i = 0; i < block.images.length; i++) {
                htmlFile += `<div style="width:${block.images[i].width || '200px'};display:inline-flex"><div class="htmlbuilder-gallery-card"  ><img  src="${block.images[i].base64}"  class="htmlbuilder-gallery-card-image"  /><br/><div class="htmlbuilder-gallery-card-description" >${block.images[i].description || ''}</div></div><br style="clear:both"/></div>`
            }
        }
        return `<div class="belongsToHeader_${lastH1} belongsToHeader_${lastH2} block-content" style="${defaultStyle}"><div class="htmlbuilder-gallery-container" style="display: flex; flex-wrap: wrap; width: 100%;" >${htmlFile}<br style="clear:both"/></div></div>`
    }
}

export default Service;