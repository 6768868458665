import React, { useState, useContext ,useEffect} from 'react'
import { Document, Page } from 'react-pdf'
import { pdfjs } from 'react-pdf';
import { Context } from '../AppContext';
import { ContextUI } from '../uiContext';
import Settings from "../AppSettings";
import PinchToZoom from 'react-pinch-and-zoom';
import StorageService from '../services/storage';
import UpdateService from '../services/updateservice';
pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.js';//`//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;;
const locationAPI = Settings.getLocationAPI();
export default function Pdf(props) {
    const context = useContext(Context);
    const contextUI = useContext(ContextUI);  

    useEffect(()=>{
        contextUI.setShowBackButton(true);
    },[])

    const [numPages, setNumPages] = useState(0);
    const [url, setURL] = useState()
    const width = window.innerWidth;
    if(width < 700){
        width = 1000;
    }
    useEffect(() => {
        getBase64Image(props.match.params.id)
    }, [props.match.params.id])

    const getDownloadLink = (DPath)=>{
        UpdateService.getDownloadToken(DPath)
        .then(token => {
            setURL(locationAPI + 'documentpreviewv3/'+token+'/?' + DPath);
        })
        .catch(ex => {
        })
    }
    const getBase64Image = function (DPath) {
        if (context.info && context.info.Index && Settings.getPlatform() !== 'web') {
            let foundDoc = context.info.Index.find((item) => { return item.DPath === DPath });
            if (foundDoc) {
                StorageService.readFileBase64(StorageService.documentsDir(), foundDoc.HFile + "_" + foundDoc.Checksum, foundDoc.Extention)
                    .then((result) => {
                        setURL(result);
                    }, (err) => {
                        console.log('Download Err',err)
                        getDownloadLink(DPath);
                    })
            } else {
                getDownloadLink(DPath);
            }

        } else {
            getDownloadLink(DPath);
        }
    }

    const onDocumentLoadSuccess = (document) => {
        const { numPages } = document;
        setNumPages(numPages);
    };


    return (
        <div className="container">
             <PinchToZoom>
            {url &&
                <Document renderMode="canvas" onLoadSuccess={onDocumentLoadSuccess} file={url} >
                    {Array.from(
                        new Array(numPages),
                        (el, index) => (
                            <React.Fragment  key={`pagefrag_${index + 1}`}>
                            <Page
                                width={width}
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                            /><hr/>
                            </React.Fragment>
                        ),
                    )}
                </Document>
            }
</PinchToZoom>
        </div>
    )
}
