import React, { useEffect } from 'react'
import { TextField, Paper, Checkbox } from '@material-ui/core'

export default function CheckBox({ item, onChange }) {

    const changeItem = (content, attribute) => {
        let newItem = Object.assign({}, item);
        if(! newItem[attribute]){ newItem[attribute] = []}
        if( newItem[attribute].indexOf(content)>=0){
            newItem[attribute].splice(newItem[attribute].indexOf(content),1)
        }else{
            newItem[attribute].push(content);
        }  
        onChange(newItem);
    }

    return (
        <Paper className="padding" style={{ marginBottom: '6px' }}>
            <span className="questionline">{item.question}</span>{item.required && <span className="required">*</span>} <br/><br/>
            <table style={{ width: '100%' }}><tbody>
                {item.options && item.options.map((option) => {
                    if (option.text.length > 0) {
                        const checked = item.answers && item.answers.indexOf(option.text)>=0;
                        return <tr key={option.id}><td><Checkbox checked={checked || false} onChange={(ev) => { changeItem(option.text, 'answers') }} /></td><td style={{ width: '95%' }} onClick={(ev) => { changeItem(option.text, 'answers') }} >{option.text}</td></tr>
                    } else {
                        return null;
                    }
                })}
            </tbody></table>
        </Paper>
    )
}
