import React from 'react'
import Attachment from './attachment';
import { List } from '@material-ui/core';
export default function answer({ line }) {
    return (
        <React.Fragment>
            <div style={{whiteSpace:'pre-line' }}>
            {[0].indexOf(line.element) >= 0 && <span>
                <h1>{line.textTitle }</h1>{line.text}
            </span>}
            {[4].indexOf(line.element) >= 0 && <span>
                <span className="questionline">{line.question}</span><br/>
                {line.answers && line.answers.join(', ')}
            </span>}
            {[5].indexOf(line.element) >= 0 && <span>
                <span className="questionline">{line.question}</span><br/>
                <List>
                    {line.answers && line.answers.map((item) => {
                        return <Attachment key={item} item={item} />
                    })
                    }
                </List>
            </span>}
            {[6].indexOf(line.element) >= 0 && <span>
                <span className="questionline">{line.question}</span><br/>
                {line.answerDateTime}
            </span>}
            {[7].indexOf(line.element) >= 0 && <span>
                <span className="questionline">{line.question}</span><br/>
                {line.answer}
            </span>}
            {[1, 2, 3].indexOf(line.element) >= 0 && <span>
                <span className="questionline">{line.question}</span><br/>
                {line.answer}
            </span>}
            <br  /> <br  />
            </div>
        </React.Fragment>
    )
}
