import React, { useState } from 'react'
import { DialogTitle, Dialog, DialogContent, DialogActions, Button, TextField, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { BorderAll } from '@material-ui/icons'; 
export default function Formelement(props) {
    const [element, setElement] = useState('');
    const [ddValues, setDDValues] = useState('');
    const escapeHTML = (html)=>{
        return new Option(html).innerHTML;
    }
    const insertItem = () => {

        let retElement = ''
        switch (element) {
            case '1':
                retElement = '<img src="img/icons/gray/check box outline.svg" class="veditorcheckbox" onclick="veditorUpdateCheckbox(this)" id="cb' + new Date().getTime() + '">'
                break;
            case '2':
                retElement = '<input type="text" class="veditortext" onkeyup="veditorUpdateTextbox(this);"  id="txt' + new Date().getTime() + '"/>';
                break;
            case '3':
                retElement = '<textarea class="veditortextarea" onkeyup="veditorUpdateTextarea(this)" id="ta' + new Date().getTime() + '"></textarea>';
                break;
            case '4':
                retElement = `<input type="text" class="veditortext" style="text-align:right" onkeyup="veditorUpdateTextbox(this);if(parseInt(this.value)>0){this.style.backgroundColor='#007902';this.style.color='white'};if(parseInt(this.value)<0){this.style.backgroundColor='#aa0009';this.style.color='white'};if(parseInt(this.value)==0){this.style.backgroundColor='white';this.style.color='black'}" id="txt' + new Date().getTime() + '"/>`;
                break;
            case '5':
                let options = ddValues.split(';').map((opt)=>{
                    return '<option value="'+escapeHTML(opt)+'">'+escapeHTML(opt)+'</option>';
                })
                retElement = '<select class="veditorselect" onchange="veditorUpdateSelect(this)" id="sel' + new Date().getTime() + '">'+options+'</select>';
                break;
            default:
                break;
        }
        props.onSave(retElement);
        props.onClose();
    }

    return (
        <Dialog onClose={() => props.onClose()} open={true} >
            <DialogTitle id="simple-dialog-title">Invoegen formulier element</DialogTitle>
            <DialogContent>
                <RadioGroup
                    aria-label="Selecteer formulier element om in te voegen"
                    name="formelement"
                    value={element}
                    onChange={(ev) => { setElement(ev.target.value) }}
                >
                    <FormControlLabel value="1" control={<Radio />} label="CheckBox / aanvink veld" />
                    <FormControlLabel value="2" control={<Radio />} label="Tekstbox / invoer veld, enkele regel" />
                    <FormControlLabel value="3" control={<Radio />} label="Tekstbox / invoer veld, meerdere regel" />
                    <FormControlLabel value="4" control={<Radio />} label="Tekstbox / invoer veld, nummerieke validatie met achtergrondkleur" />
                    <FormControlLabel value="5" control={<Radio />} label="Dropdown / selectie uit items" />

                </RadioGroup>
                {element === '5' && <TextField
                    fullWidth
                    id="dropdownlabels"
                    label="Geef hier de opties aan, gescheiden door een ;"
                    value={ddValues}
                    onChange={(ev) => { setDDValues(ev.target.value) }}
                    margin="normal"
                />

                }

            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={() => { props.onClose() }}>Annuleren</Button><Button color="primary" variant="contained" onClick={() => { insertItem() }}>Invoegen</Button>
            </DialogActions>
        </Dialog>
    )
}
