import React, { useState, useEffect } from 'react'
import { DialogTitle, Dialog, DialogContent, DialogActions, Button, TextField, Select, MenuItem, FormControl, InputLabel, FormControlLabel, Checkbox } from '@material-ui/core';

export default function ImageUpload(props) {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [url,setURL] = useState('');
    const [useBox, setUseBox] = useState(false); 
    const [uploadedImage, setUploadedImage] = useState();
    const [width, setWidth] = useState('400px'); 
    const [align, setAlign] = useState('right');
    const insertItem = () => {
       
        let alignText = align === '-' ? '' : `align="${align}"`;
        let widthText = width !== '0' ? `width="${width}"` :''
        let insertImage = `<video controls ${widthText} ${alignText}   alt="${title}"  class="paragraphvideo"> <source src="${url}" ></video>`
        if(useBox){
            insertImage = `<div class="imagebox" style="width:${width};float:${align}"}><video controls alt="${title}"  class="paragraphvideo"><source src="${url}" ></video><br/><div class="imageboxtext">${description}</div></div>`
        }
        props.onSave(insertImage);     

        props.onClose();
    }
 
    const uploadFile = (file) => {
        const reader = new FileReader();
        reader.addEventListener("load", function () {
            let base64 = reader.result;
            if (base64.indexOf('data:image') === 0) {
                //  setBase64Image(base64);
                let image = new Image();
                image.src = base64;

                image.onload = function (ev) {
                    setUploadedImage(image);
                }
            }
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }
    }
    const processFile = (ev) => {
        for (let i = 0; i < ev.target.files.length; i++) {
            uploadFile(ev.target.files[i]);
        }
    }

    return (
        <Dialog onClose={() => props.onClose()} open={true} >
            <DialogTitle id="simple-dialog-title">Invoegen video</DialogTitle>
            <DialogContent>
                <TextField
                    margin="dense"
                    id="name"
                    label="Naam voor de video"
                    type="text"
                    value={title || ""}
                    onChange={(ev) => { setTitle(ev.target.value) }}
                    fullWidth
                />
               <TextField
                    margin="dense"
                    id="url"
                    label="Link/url"
                    type="text"
                    value={url || ""}
                    onChange={(ev) => { setURL(ev.target.value) }}
                    fullWidth
                />
                <FormControl fullWidth >
                    <InputLabel id="width">Breedte in de weergave</InputLabel>
                    <Select fullWidth value={width} labelId="width" onChange={(ev) => { setWidth(ev.target.value) }}>
                        <MenuItem value={'0'}>Origineel formaat</MenuItem>
                        <MenuItem value={'100%'}>100%</MenuItem>
                        <MenuItem value={'100px'}>100px</MenuItem>
                        <MenuItem value={'200px'}>200px</MenuItem>
                        <MenuItem value={'400px'}>400px</MenuItem>
                        <MenuItem value={'600px'}>600px</MenuItem>
                        <MenuItem value={'800px'}>800px</MenuItem>
                        <MenuItem value={'1000px'}>1000px</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth >
                    <InputLabel id="lining">Uitlijning</InputLabel>
                    <Select fullWidth value={align} labelId="lining" onChange={(ev) => { setAlign(ev.target.value) }}>
                        <MenuItem value={'-'}>Geen</MenuItem>
                        <MenuItem value={'left'}>Links</MenuItem>
                        <MenuItem value={'right'}>Rechts</MenuItem>
                        <MenuItem value={'middle'}>Midden</MenuItem>
                        <MenuItem value={'top'}>Boven</MenuItem>
                        <MenuItem value={'bottom'}>Onder</MenuItem>
                    </Select>
                </FormControl>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={useBox}
                            onChange={(ev)=>{setUseBox(ev.target.checked)}}
                            name="useBox"
                            color="primary"
                        />
                    }
                    label="Kader afbeelding"
                />  
               {useBox &&  <TextField
                    margin="dense"
                    id="description"
                    label="Beschrijving"
                    type="text"
                    value={description || ""}
                    onChange={(ev) => { setDescription(ev.target.value) }}
                    fullWidth
                />}
                <br />
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={() => { props.onClose() }}>Annuleren</Button><Button color="primary" variant="contained" onClick={() => { insertItem() }} >Invoegen</Button>
            </DialogActions>
        </Dialog>
    )
}
