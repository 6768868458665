import React, { useEffect } from 'react'
import { TextField, Paper, RadioGroup, FormControlLabel, Radio,Switch,IconButton ,Divider} from '@material-ui/core'
import IconDelete from '@material-ui/icons/Delete'
import IconUp from '@material-ui/icons/VerticalAlignTop';
import IconDown from '@material-ui/icons/VerticalAlignBottom';
export default function RadioButton({ item, onChange ,onDelete,onChangeSort}) {

    useEffect(() => {
        if (!item.options) {
            changeItem([], 'options');
        } else if (!item.options.find(option => { return option.text.length === 0 })) {
            let options = Object.assign([], item.options);
            options.push({ id: new Date().getTime(), text: '' })
            changeItem(options, 'options');
        }
    }, [item])

    const changeItemOption = (value, option,attribute) => { 
        let options = Object.assign([], item.options);
        for (let o = 0; o < options.length; o++) {
            if (options[o].id === option.id) {
                options[o][attribute] = value;
            }
        }
        changeItem(options, 'options')
    }

    const changeItem = (content, attribute) => {
        let newItem = Object.assign({}, item);
        newItem[attribute] = content;
        onChange(newItem);
    }
    return (
        <Paper className="padding" style={{ marginBottom: '6px' }}>
         <TextField fullWidth placeholder="Vraag" value={item.question || ''} onChange={(ev) => { changeItem(ev.target.value, 'question') }} ></TextField>

            <table style={{width:'100%'}}><tbody>
                {item.options && item.options.map((option) => {
                    return <tr key={option.id}><td><Radio disabled /></td><td style={{width:'50%'}}><TextField fullWidth placeholder="Antwoord..." value={option.text || ''} onChange={(ev) => { changeItemOption(ev.target.value, option,'text') }} ></TextField></td><td style={{width:'50%'}}><TextField fullWidth placeholder="Ontvanger (E-mailadres)" value={option.recipient || ''} onChange={(ev) => { changeItemOption(ev.target.value, option,'recipient') }} ></TextField></td></tr>
                })}
            </tbody></table>
            <br/><br/><Divider />
            <IconButton onClick={()=>{onChangeSort(item,'up')}}><IconUp/></IconButton>
            <IconButton  onClick={()=>{onChangeSort(item,'down')}}><IconDown/></IconButton>
            <div style={{float:'right'}}>
            {onDelete && <IconButton onClick={onDelete}><IconDelete /></IconButton>}
            <FormControlLabel labelPlacement="start" control={<Switch checked={item.showDropdown || false} value="showDropdown" onChange={() => { changeItem(!item.showDropdown, 'showDropdown') }} />} label="Toon als 'dropdown' weergave" />
             <FormControlLabel labelPlacement="start" control={<Switch checked={item.required || false} value="required" onChange={()=>{changeItem(!item.required,'required')}}/>} label="Verplicht" /></div>
            <br style={{clear:'both'}}/>

        </Paper>
    )
}
