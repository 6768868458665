//polyfills
//import 'babel-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import "core-js/stable";
import "regenerator-runtime/runtime";
import 'core-js';
import 'core-js/es/map';
import 'core-js/es/set';
import 'raf/polyfill';
 
//veditor global scripts
import './components/veditor/scripts';

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
  