import React from 'react'
import { TextField,Paper } from '@material-ui/core'
import DatePicker from "../../general/datepicker";
import moment from 'moment';
import 'moment/locale/nl';
moment.locale('nl');
export default function DateTime({item,onChange}) {
    const changeDateItem = (value)=>{ 
        let newItem = Object.assign({},item);
        newItem.answer = value;
        newItem.answerDateTime = value && moment(value).format( newItem.showTime ? 'dd DD MMM YYYY HH:mm' : 'dd DD MMM YYYY');
        onChange(newItem);
    }
    return (
        <Paper className="padding" style={{marginBottom:'6px'}}>
          <span className="questionline">{item.question}</span>{item.required && <span className="required">*</span>} <br/><br/>
           <DatePicker label="Selecteer een datum" value={item.answer||''}  showTimeSelect={item.showTime} onChange={changeDateItem}  style={{ borderBottom: '1px solid gray', padding: '4px' }} />
         </Paper>
    )
}
