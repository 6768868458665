import React from 'react'
import { TextField,Paper, FormControlLabel,Switch,IconButton ,Divider} from '@material-ui/core'
import IconDelete from '@material-ui/icons/Delete'
import IconUp from '@material-ui/icons/VerticalAlignTop';
import IconDown from '@material-ui/icons/VerticalAlignBottom';
import DatePicker from "../../general/datepicker";
export default function DateTime({item,onChange,onDelete,onChangeSort}) {
    const changeItem = (content,attribute)=>{ 
        let newItem = Object.assign({},item);
        newItem[attribute] = content;
        onChange(newItem);
    }
    const changeDate = ()=>{ 
      
    }
    return (
        <Paper className="padding" style={{marginBottom:'6px'}}>
           <TextField fullWidth  placeholder="Vraag" value={item.question || ''} onChange={(ev)=>{changeItem(ev.target.value,'question')}} ></TextField>
        
            <DatePicker label="Selecteer een datum" value={new Date()}  showTimeSelect={item.showTime} onChange={changeDate}  style={{ borderBottom: '1px solid gray', padding: '4px' }} /><br /><br />
         
            <Divider />
            <IconButton onClick={()=>{onChangeSort(item,'up')}}><IconUp/></IconButton>
            <IconButton  onClick={()=>{onChangeSort(item,'down')}}><IconDown/></IconButton>
            <div style={{float:'right'}}> 
            {onDelete && <IconButton onClick={onDelete}><IconDelete /></IconButton>}
            <FormControlLabel labelPlacement="start" control={<Switch checked={item.showTime || false} value="showTime" onChange={()=>{changeItem(!item.showTime,'showTime')}}/>} label="Inclusief tijd" />
            <FormControlLabel labelPlacement="start" control={<Switch checked={item.required || false} value="required" onChange={()=>{changeItem(!item.required,'required')}}/>} label="Verplicht" /></div>
            <br style={{clear:'both'}}/>
         </Paper>
    )
}
