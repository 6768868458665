import React, { useEffect } from 'react'
import { TextField, Paper, Radio, Select ,MenuItem,FormControlLabel} from '@material-ui/core'

export default function Score({ item, onChange }) {

    const changeItem = (content, attribute) => {
        let newItem = Object.assign({}, item);
        newItem[attribute] = content;
        onChange(newItem);
    }

    const checkBoxes = [];
    const start = item.scoreStart || 0;
    const end = item.scoreEnd || 10;
    for(let i=start;i<=end;i++){
        checkBoxes.push(<FormControlLabel key={i}
        value="top"
        control={<Radio color="primary" checked={i=== item.answer} onChange={(ev) => { changeItem(i, 'answer') }} style={{padding:'4px'}}/>}
        label={i}
        labelPlacement="top"
        style={{marginLeft:'0px',marginRight:'0px'}}
      />)
    }

    return (
        <Paper className="padding" style={{ marginBottom: '6px' }}>
            <span className="questionline">{item.question}</span>{item.required && <span className="required">*</span>} <br /><br />


            <table style={{width:'100%'}}><tbody><tr>
                <td style={{width:'20%'}}>
                {item.scoreStartLabel }
                </td>
                <td style={{textAlign:'center'}}>
                    {checkBoxes}
                </td>
                <td style={{width:'20%'}}>
                {item.scoreEndLabel }
                </td>
            </tr></tbody></table>



        

        </Paper>
    )
}
