import React from 'react'
import { TextField, Paper, FormControlLabel, Switch, IconButton, Divider, Checkbox } from '@material-ui/core'
import IconDelete from '@material-ui/icons/Delete'
import IconUp from '@material-ui/icons/VerticalAlignTop';
import IconDown from '@material-ui/icons/VerticalAlignBottom';
export default function SingleLine({ item, onChange, onDelete, onChangeSort }) {
    const changeItem = (content, attribute) => {
        let newItem = Object.assign({}, item);
        newItem[attribute] = content;
        onChange(newItem);
    }
    return (
        <Paper className="padding" style={{ marginBottom: '6px' }}>
            <TextField fullWidth placeholder="Vraag" value={item.question || ''} onChange={(ev) => { changeItem(ev.target.value, 'question') }} ></TextField>
            <TextField fullWidth placeholder="Kort antwoord" disabled ></TextField>
            {((item.question && (item.question.toLowerCase().indexOf('email') >= 0 || item.question.toLowerCase().indexOf('e-mail') >= 0)) || item.emailowner === true) && <div >
                <br /> <FormControlLabel labelPlacement="end" control={<Checkbox checked={item.emailowner || false} onChange={() => { changeItem(!item.emailowner, 'emailowner') }} />} label="Gebruik dit veld als emailadres van de verzender." />
            </div>}
            {((item.question && (item.question.toLowerCase().indexOf('naam') >= 0 || item.question.toLowerCase().indexOf('name') >= 0)) || item.nameowner === true) && <div >
                <br /> <FormControlLabel labelPlacement="end" control={<Checkbox checked={item.nameowner || false} onChange={() => { changeItem(!item.nameowner, 'nameowner') }} />} label="Gebruik dit veld als naam van de verzender." />
            </div>}
            {((item.question && (item.question.toLowerCase().indexOf('functie') >= 0 || item.question.toLowerCase().indexOf('function') >= 0)) || item.functionowner === true) && <div >
                <br /> <FormControlLabel labelPlacement="end" control={<Checkbox checked={item.functionowner || false} onChange={() => { changeItem(!item.functionowner, 'functionowner') }} />} label="Gebruik dit veld als functie van de verzender." />
            </div>}
            <br /><br /><Divider />
            <IconButton onClick={() => { onChangeSort(item, 'up') }}><IconUp /></IconButton>
            <IconButton onClick={() => { onChangeSort(item, 'down') }}><IconDown /></IconButton>
            <div style={{ float: 'right' }}>
                {onDelete && <IconButton onClick={onDelete}><IconDelete /></IconButton>}
                <FormControlLabel labelPlacement="start" control={<Switch checked={item.required || false} value="required" onChange={() => { changeItem(!item.required, 'required') }} />} label="Verplicht" /></div>
            <br style={{ clear: 'both' }} />
        </Paper>
    )
}
