import React, { useState, useEffect } from 'react'
import {Dialog,DialogContent,DialogTitle,DialogActions,Button, Typography, Tabs,   IconButton} from '../ui/core'  

import Answers from './view/answers';
import Settings from './edit/settings';
import Builder from './builder';
import GeneralService from '../../services/general';
import FillInForm from './fillinform';
export default function FormBuilder(props) {
    const [selectedTab, setSelectedTab] = useState(0);
    const [form, setForm] = useState(props.form);
    const [answers, setAnswers] = useState(props.answers)

    const save = () => {
        props.onSaveForm(form); 
    }

    useEffect(()=>{
        setForm(props.form);
    },[props.form])

    useEffect(()=>{
        setAnswers(GeneralService.sortJSON(props.answers,'dateSend','321'));
    },[props.answers])

    return (
        <Dialog  fullScreen  open={true} onClose={props.onClose}   className="padding-ios">
            <DialogTitle> <Typography component="h2">{form.title} </Typography></DialogTitle>
            <Tabs  value={selectedTab} onChange={setSelectedTab} >
                <div className="ui-tab">Vragen</div>  
                <div className="ui-tab">Instellingen</div>  
                <div className="ui-tab">Voorbeeld</div>  
                <div className="ui-tab">Antwoorden</div>   
            </Tabs>
            <DialogContent  >
                {selectedTab === 0 && <Builder items={form.lines} onChange={(lines) => { let newForm = Object.assign({}, form); newForm.lines =  GeneralService.sortJSON(lines,'sort','123'); setForm(newForm) }} />}
                {selectedTab === 1 && <Settings form={form} onChange={(newForm)=>{setForm(newForm)}}></Settings>}
                {selectedTab === 2 && <FillInForm form={form} readonly={true} />}
                {selectedTab === 3 && <Answers form={form}  answers={answers}></Answers>}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>Annuleren</Button>
                <Button variant="contained" color="primary" onClick={save}>Opslaan</Button>
            </DialogActions>
        </Dialog>
    )
}
