import React, { useEffect } from 'react'
import { TextField, Paper, Radio, Select ,MenuItem} from '@material-ui/core'

export default function RadioButton({ item, onChange }) {

    const changeItem = (content, attribute) => {
        let newItem = Object.assign({}, item);
        newItem[attribute] = content;
        onChange(newItem);
    }

    return (
        <Paper className="padding" style={{ marginBottom: '6px' }}>
            <span className="questionline">{item.question}</span>{item.required && <span className="required">*</span>} <br /><br />

            {item.showDropdown ?
                <Select
                id="selectbutton"
                value={item.answer || ''}
                onChange={(ev) => { changeItem(ev.target.value, 'answer') }}
                fullWidth
              >
                   {item.options && item.options.map((option) => {
                        if (option.text.length > 0) {
                            return <MenuItem  key={option.id} value={option.text }>{option.text}</MenuItem>
                        }else{
                            return null
                        }
                   })}
              </Select>:
                <table style={{ width: '100%' }}><tbody>
                    {item.options && item.options.map((option) => {
                        if (option.text.length > 0) {
                            return <tr key={option.id}><td><Radio checked={option.text === item.answer} onChange={(ev) => { changeItem(option.text, 'answer') }} /></td><td style={{ width: '95%' }} onClick={(ev) => { changeItem(option.text, 'answer') }} >{option.text}</td></tr>

                        } else {
                            return null;
                        }
                    })}
                </tbody></table>
            }

        </Paper>
    )
}
