import React, { useState, useContext } from 'react'
import HtmlEditor from './edit/html';
import HtmlBuilder from './edit/htmlbuilder'
import FormEditor from './edit/form';
import AddressBookEditor from './edit/addressbook';
import Rename from './edit/rename';
import DeleteItem from './edit/delete';
import MoveItem from './edit/move';
import GeneralService from '../../../services/general';
import UpdateService from '../../../services/updateservice';
import StorageService from '../../../services/storage';
import FileService from '../../../services/file';
import FilesService from '../../../services-admin/files';
import ShareLinks from './edit/shareLinks'
import { Context } from '../../../AppContext';
import Settings from "../../../AppSettings";
import { IconButton, Menu, MenuItem } from '../../ui/core';
import { Edit as EditIcon, LowPriority as CopyIcon, Delete as DeleteIcon, FontDownload as EditNameIcon, Download as DownloadIcon, Link } from '../../ui/coreIcons';

import { MoreVert } from '../../ui/coreIcons';

export default function FilesMenu(props) {
    const locationAPI = Settings.getLocationAPI();
    const context = useContext(Context);
    const fileType = props.file.filename.split('.').pop().toLowerCase();
    const [showHtml, setShowHtml] = useState(false)
    const [showRename, setShowRename] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [showForm, setShowForm] = useState(false);
    const [showMove, setShowMove] = useState(false);
    const [showAddress, setShowAddress] = useState(false);
    const [showHtmlBuilder, setShowHtmlBuilder] = useState(false);
    const [showLink, setShowLink] = useState(false);

    const handleEdit = (type) => {
        switch (type) {
            case 'html':
                setShowHtml(true);
                break;
            case 'htmlbuilder':
                setShowHtmlBuilder(true);
                break;
            case 'form':
                setShowForm(true);
                break;
        }
    }

    const handleConvertNote = () => {
        let newContent = GeneralService.processTags(props.file.content, []);
        FilesService.saveBase64(props.version, props.access, props.level, props.file.filename.replace(props.file.filename.split('.').pop(), 'html'), FilesService.encodeUnicode(newContent))
            .then((result) => {
                props.onRefresh();
            }, (err) => { props.onRefresh(); })
    }
    const download = () => {
        FileService.viewFile(props.file.DPath, StorageService.getStorageFileName(props.file), props.file.Name, true)
            .then(result => {

            })
            .catch(err => {
                context.setMessage('Het bestand kan niet worden geopend.')
                console.log(err);
            })
        /*
UpdateService.getDownloadToken(props.file.DPath)
.then(token => {
// window.open(locationAPI + 'documentpreviewv3/' + token + '/?' + props.file.DPath, '_blank');
window.open(locationAPI + 'documentv3/' + token + '/?' + props.file.DPath, '_blank');
})
.catch(ex => {
window.open(locationAPI + 'documentpreview/?' + props.file.DPath, '_blank');
//context.setMessage('Er is een fout opgetreden bij het openen van het bestand.')
})
*/
    }
    // {(fileType === 'html' && !context.isBeta()) && <MenuItem onClick={() => { handleEdit('html') }}> <EditIcon /> Bewerk alinea </MenuItem>}
    // {(fileType === 'html' && context.isBeta()) && <MenuItem onClick={() => { handleEdit('htmlbuilder') }}> <EditIcon /> Bewerken</MenuItem>}

    return (
        <div>
            {showForm && <FormEditor onRefresh={props.onRefresh} version={props.version} access={props.access} level={props.level} file={props.file} files={props.files} onClose={() => { setShowForm(false) }} />}
            {showAddress && <AddressBookEditor onRefresh={props.onRefresh} version={props.version} access={props.access} level={props.level} file={props.file} files={props.files} onClose={() => { setShowAddress(false) }} />}
            {showHtml && <HtmlEditor onRefresh={props.onRefresh} version={props.version} access={props.access} level={props.level} file={props.file} files={props.files} pages={props.pages} onClose={() => { setShowHtml(false) }} />}
            {showHtmlBuilder && <HtmlBuilder onRefresh={props.onRefresh} version={props.version} access={props.access} level={props.level} file={props.file} files={props.files} pages={props.pages} onClose={() => { setShowHtmlBuilder(false) }} />}
            {showRename && <Rename onRefresh={props.onRefresh} version={props.version} access={props.access} level={props.level} file={props.file} onClose={() => { setShowRename(false) }} />}
            {showDelete && <DeleteItem onRefresh={props.onRefresh} version={props.version} access={props.access} level={props.level} file={props.file} onClose={() => { setShowDelete(false) }} />}
            {showMove && <MoveItem onRefresh={props.onRefresh} version={props.version} access={props.access} level={props.level} file={props.file} onClose={() => { setShowMove(false) }} />}
            {showLink && <ShareLinks file={'/' + props.version + '/' + props.access + '/' + props.level + '/' + props.file.filename} onClose={() => { setShowLink(false) }} />}
            <Menu
                id="menu"
                control={<IconButton><MoreVert /></IconButton>}
            >
                {(fileType === 'html') && <MenuItem onClick={() => { handleEdit('htmlbuilder') }}> <EditIcon /> Bewerken</MenuItem>}
                {(fileType === 'form') && <MenuItem onClick={() => { handleEdit('form') }}><EditIcon />Bewerk formulier</MenuItem>}
                {(fileType === 'note') && <MenuItem onClick={() => { handleConvertNote() }}><EditNameIcon />Omzetten naar tekstblok</MenuItem>}
                {(props.file.filename === 'adresboek.json') && <MenuItem onClick={() => { setShowAddress(true) }}><EditIcon />Bewerk adresboek</MenuItem>}
                {props.file.filename !== 'adresboek.json' && <MenuItem onClick={() => { setShowRename(true) }}><EditNameIcon />Wijzig naam</MenuItem>}
                <MenuItem onClick={() => { setShowDelete(true) }}><DeleteIcon />Verwijderen</MenuItem>
                <MenuItem onClick={() => { setShowMove(true) }}><CopyIcon />Verplaatsen</MenuItem>
                {props.file.filename.indexOf('.form') === -1 && <MenuItem onClick={() => { download() }}><DownloadIcon />Downloaden</MenuItem>}
                {(fileType !== 'html' && fileType !== 'form' && fileType !== 'note' && props.file.filename !== 'adresboek.json') && <MenuItem onClick={() => { setShowLink(true) }}><Link />Deelbare link</MenuItem>}
            </Menu>
        </div>
    )
}
