import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import ReactDOMServer from 'react-dom/server';
import { List, ListItem, ListItemText, ListItemIcon } from '../ui/core';
import { Person, Business } from '../ui/coreIcons';
import AddressBookPopup from "./addressbookpopup";

export default function AddressbookContact(props) {
    const [popup, setPopup] = useState(null);

    const onClickContact = (contact) => {
        setPopup(<AddressBookPopup contact={contact} onClose={() => { setPopup(null) }} />)
    }

    let name = '';
    if (props.contact.Achternaam) {
        name = <>
            <b>{props.contact.Achternaam}, {props.contact.Voornaam} {props.contact.Tussenvoegsel}</b><br />
            {props.contact.Organisatie} {props.contact.Gemeente}

        </>
    } else {
        name = <>
            <b>{props.contact.Organisatie} {props.contact.Gemeente}</b><br />
            {props.contact.Adres} {props.contact.Woonplaats}
        </>
    }

    let funct = <>
        {props.contact.Functie && props.contact.Functie + ', '}
        {props.contact.PiketFunctie && props.contact.PiketFunctie + ', '} 
        {props.contact.FunctieinCoPI && props.contact.FunctieinCoPI + ', '}
        {props.contact.FunctieinROT && props.contact.FunctieinROT + ', '}
        {props.contact.FunctieinBT && props.contact.FunctieinBT + ', '}      
        {props.contact.FunctieMeldkamer && props.contact.FunctieMeldkamer + ', '}
        {props.contact.FunctieActiecentrum && props.contact.FunctieActiecentrum + ', '}        
        {props.contact.Functieoverig && props.contact.Functieoverig + ', '}
        </>
    funct = ReactDOMServer.renderToStaticMarkup(funct)
    if(funct.length > 0){
        funct = funct.substr(0,funct.length-2)
    }
    const businessImage =   "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAcpJREFUaEPtWYtNxDAMfTcBbAAbwAbAJDACTAA3AYwAkwAbwAawAUxw6EmxZPncpm2afjhbqnSXJr44z8+f3AYrl83K948wYG4EA4E1I/AG4GIkA94BXA7RVeJCYYA68dkRuAJARLQ8ALhPA1sA/K6FLvOaBsKAIfzRHAgE5o5Ci0RACNbkXucAjtPLDwA/ZuIpAD6Ur/ToKVxLHZQqJN4NIcbANWGAd3AaAfq4lScAZ2nwDgDdSMsNgOs08ALg2byn+zxO5UJeybH4MKoRCAPmKCX+FQK2ECP3SFKJ8yQoY70WFmtS45MvttjjWuqQPGFJboMGC8I9aesHpswDXdKHu9eDMcCDj/CfqDjvuZC0nMy0ngtJnvh28gRVSz/Bz0UIzBVGc4Gk9WIrt3iKRJbbQxggPl6rHxgNAe/OhsWc1PO3DcWcxHnGeK+Yow4KC0HqsKKJX0TiLnG69pzDNoBx3Ard56jh2D+dFpMtpPQPdtmv44Kco68uixDI5QG7IY/U+iLLzm9qKUcjcRiQKtOmm45AwONizv/artcXx4G+Mb6vAV30F0WhLj+g54QBXbvHkr+Y+qJSZX4YUOVYeygNBHocVpWpq0fgDx02ujFNrFbeAAAAAElFTkSuQmCC";
const personImage = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAnFJREFUaEPtl41NHDEQhR8VBCpIqACoAFJBoIKQCkIHgQ6gg6QCQgVABZAKgA6SCkBfZEuj1d16bM8BJ3mk02pvx5735scz3tCay8aa49cg8NYRHBEYEej0wEihTgd2L19FBHYlfZf0SdJBQngj6VHShaT7btRmg0gCm5J+SDopADxNRP5GEIkk8FvSFycodI+curNqUQQOJV0aS7eS8DSpg5BKvO8bHQhApEuiCJAOHxKSX5KOl6D6Kelr+saarS70UsgsRNHeJSBPknhflt/UCUX8Menv9RZ1RARs+lxJ4n1ObK10p1EEAXKb0wc5S7k+R6BWf+VFXBsBCjsX87uIAA3rwRTmdqEG0KUWEHRpcM0SkUIYB0QuzLkz3uY/BQ/5LokiwDl/bZBw0pDrf9J/O+mdEyrLZ9MnmklEEQCAPeNLgJiJSiNHaY//3yMJsB+g8HxualMQ/9L3cxc6h1I0AUyS13Ri0iWnDCnFjyh1Fe2U0yoIOPwWpzIIxPmybaeoCJD3dFd7C8u3sYwsj9Y8qQeO2O566CFAgTIaM0q0NiSIQIgRvOmqWUuAEQDQHJetoJflCgQ4Xplo3dfNGgKAZurMc4wFwljAmEBKAITnND0gzI/I8SRyefywewE+35uLheElsKjLAhqPZeBFYwsUcs+gb0zJYPNbaVMPgemcw303Ay/tX/MdEkSZuck9L3kI2AnSc+OqAb1It8qeh4C9sHfP7w529n5BHWFzqXgIPJvVHn0HxqKK26YHkHuzIiy/gttmLQE/hDjNWYyDQJyj2+rUE4FXwNhuYhBo913MyhGBGD+27zIi0O67mJVrH4EXKzJqMc3MzRkAAAAASUVORK5CYII="
    return (
        <React.Fragment>
            {popup}
            <List>
                <ListItem onClick={() => { onClickContact(props.contact) }}>                    
                    <ListItemIcon> {props.contact.Image ? <div src={props.contact.Image} className="avatar" style={{backgroundImage:`url(${props.contact.Image})`}}>&nbsp;</div> :  props.contact.Achternaam ? <div  className="avatar" style={{backgroundImage:`url(${personImage})`,backgroundSize:'60%'}}></div>: <div  className="avatar" style={{backgroundImage:`url(${businessImage})`,backgroundSize:'60%'}}>&nbsp;</div>}</ListItemIcon>
                    <ListItemText primary={name} secondary={funct}></ListItemText>
                </ListItem>
            </List>
        </React.Fragment>
    )
}
