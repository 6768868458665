import React from 'react'

import IconUp from '../icons/up.svg';
import IconDown from '../icons/down.svg';
import IconDelete from '../icons/delete.svg';
import { IconButton, CheckBox, Paper, Select } from '../../../ui/core';

import ParagraphImageAlign from '../settings/image-align';
import HeadingSettings from '../settings/heading';
import VideoSettings from '../settings/video';
import TableSettings from './table';
export default function Block({ builderFile, block, onChange,onChangeBuilderFile }) {
  //  const selectedBlock = builderFile && builderFile.blocks.find(block => { return block.selected === true })

    const handleChangeBlockAttribute = (block, attribute, value) => {
        let tmpBlock = Object.assign({}, block);
        tmpBlock[attribute] = value; 
        onChange(tmpBlock);
    }
    const handleChangeBlock = (block) => { 
        onChange(block);
    }

    const handleChangeDirection = (block, direction) => {
        let tmpBuilderFile = Object.assign({}, builderFile);
        let divider = block.sort + direction;
        for (let i = 0; i < tmpBuilderFile.blocks.length; i++) {
            if (block._id === tmpBuilderFile.blocks[i]._id) {
                tmpBuilderFile.blocks[i].sort = divider;
            } else {
                if (tmpBuilderFile.blocks[i].sort === divider) {
                    if (direction > 0) {
                        tmpBuilderFile.blocks[i].sort = tmpBuilderFile.blocks[i].sort - 1;
                    } else {
                        tmpBuilderFile.blocks[i].sort = tmpBuilderFile.blocks[i].sort + 1;
                    }
                }
            }
        }
        tmpBuilderFile.blocks = tmpBuilderFile.blocks.sort((a, b) => { return a.sort - b.sort })
        for (let i = 0; i < tmpBuilderFile.blocks.length; i++) {
            tmpBuilderFile.blocks[i].sort = i;
        }
        onChangeBuilderFile(tmpBuilderFile);
    }

    const handleDelete = (block) => {
        if(window.confirm("Weet u zeker dat u dit blok wilt verwijderen?")){
            let tmpBuilderFile = Object.assign({}, builderFile);
            for (let i = tmpBuilderFile.blocks.length - 1; i >= 0; i--) {
                if (block._id === tmpBuilderFile.blocks[i]._id) {
                    tmpBuilderFile.blocks.splice(i, 1);
                }
            }
            onChangeBuilderFile(tmpBuilderFile);
        }
      
    }
    
    if (!block) return null
    return ( 
        <div className="padding" style={{height:'2000px'}}>
            <Paper className="padding">
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '310px' }}> <b>Geselecteerde blok</b> </div>
                    <div><IconButton onClick={() => { handleChangeDirection(block, -1) }}>   <img src={IconUp} />  </IconButton></div>
                    <div><IconButton onClick={() => { handleChangeDirection(block, 1) }}>   <img src={IconDown} /> </IconButton></div>
                    <div><IconButton onClick={() => { handleDelete(block) }}>   <img src={IconDelete} /></IconButton></div>
                </div>
                <br />
                <CheckBox id="publish" checked={block.publish === true} label="Publiceren" onChange={(ev) => handleChangeBlockAttribute(block, ev.target.id, ev.target.checked)} />

                {(block.headingSize === 'h1' || block.headingSize === 'h2') && <>
                    <CheckBox id="collapse" checked={block.collapse === true} label="Hoofdstuk/paragraaf inklapbaar" onChange={(ev) => handleChangeBlockAttribute(block, 'collapse', ev.target.checked)} />
                    {block.collapse && <CheckBox id="collapsedefaultopen" checked={block.collapsedefaultopen === true} label="Hoofdstuk/paragraaf standaard open" onChange={(ev) => handleChangeBlockAttribute(block, 'collapsedefaultopen', ev.target.checked)} />}
                </>}

                {(block.type === 'paragraphimage' || block.type === 'paragraphyoutube') && <ParagraphImageAlign block={block} onChange={handleChangeBlock} />}
                {block.type === 'paragraphyoutube' && <VideoSettings block={block} onChange={handleChangeBlock} />}

                {block.type === 'heading' && <HeadingSettings block={block} onChange={handleChangeBlock} />}

                {(block.type === 'paragraphimage' || block.type === 'paragraph' || block.type === 'paragraphyoutube') && <Select label="Type" value={block.type} onChange={(ev) => { handleChangeBlockAttribute(block,'type', ev.target.value) }}>
                    <option value={'paragraph'}>tekstblok</option>
                    <option value={'paragraphimage'}>tekstblok met afbeelding</option>
                    <option value={'paragraphyoutube'}>tekstblok met Youtube video</option>
                </Select>}

                {block.type === 'table' && <TableSettings block={block} onChange={handleChangeBlock}></TableSettings>}
            </Paper>
        </div>
    )
}
