
import React, { useState,useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import moment from 'moment';
import 'moment/locale/nl';
import IconButton from '@material-ui/core/IconButton';
import IconPrevious from '@material-ui/icons/ChevronLeft';
import IconNext from '@material-ui/icons/ChevronRight';
import IconDay from '@material-ui/icons/WbSunnyOutlined';
import IconNight from '@material-ui/icons/NightsStayOutlined';
import { DialogTitle, AppBar, Tab, Tabs } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
moment.locale('nl');
const FormDialog = function (props) { 
    const [date, setDate] = useState(props.value ? moment(props.value) : null);
    const [selectedDate, setSelectedDate] = useState(props.value ? moment(props.value) : moment());
    const [selectedMonth, setSelectedMonth] = useState(date ? date.clone().startOf('month') : moment().startOf('month'));
    const [showSelection, setShowSelection] = useState(false);
    const [showPart, setShowPart] = useState(0);
    const days = ['ZO', 'MA', 'DI', 'WO', 'DO', 'VR', 'ZA'];
    const padLeft = (data, size, paddingChar) => {
        return (new Array(size + 1).join(paddingChar || '0') + String(data)).slice(-size);
    }
    const primary = props.theme.palette.primary.main;

    useEffect(()=>{
        setDate(props.value ? moment(props.value) : null);
    },[props.value])

    const moveToDate = (year, month, day) => {
        let tmpDate = selectedDate.clone();
        tmpDate.set({ 'year': year, 'month': month, 'date': day });
        setSelectedDate(tmpDate);
    }
    const moveToHour = (hour) => {
        let tmpDate = selectedDate.clone();
        tmpDate.set({ 'hour': hour });
        setSelectedDate(tmpDate);
    }
    const moveToMinute = (minute) => {
        let tmpDate = selectedDate.clone();
        tmpDate.set({ 'minute': minute });
        setSelectedDate(tmpDate);
    }
    const getRows = () => {
        let rows = [];
        let cells = [];
        for (let i = 0; i < 7; i++) {
            cells.push(<td key={i} style={{ textAlign: 'center' }}>{days[i]}</td>)
        }
        rows.push(<tr key='rowsdays'>{cells}</tr>)

        let pointer = selectedMonth.clone().day(0);
        let last = selectedMonth.clone().endOf('month').day(6);

        let weekRows = []
        while (pointer.isBefore(last)) {
            if (pointer.day() === 0) {
                cells = [];
            }
            let style = {};
            style.cursor = 'pointer';
            style.textAlign = 'center';
            if (pointer.month() !== selectedMonth.month()) {
                style.color = 'silver'
            }
            if (pointer.format('YYYYMMDD') === moment().format('YYYYMMDD')) {
                style.backgroundColor = '#efefef'
            }
            if (pointer.format('YYYYMMDD') === selectedDate.format('YYYYMMDD')) {
                style.backgroundColor = primary;
                style.color = 'white';
            }

            const dt = pointer.clone();
            cells.push(<td key={dt.toString()} style={style} onClick={() => { moveToDate(dt.year(), dt.month(), dt.date()) }}>{pointer.format('DD')}</td>);
            if (pointer.day() === 6) {
                weekRows.push(<tr key={'blankcell_' + dt.toString()}>{cells}</tr>)
            }
            pointer.add(1, 'days');
        }
        if (weekRows.length < 6) {
            weekRows.push(<tr key='blankrow'><td colSpan={7}>&nbsp;</td></tr>)
        }
        return <table cellPadding={10} cellSpacing={0} style={{ width: '100%' }}><tbody>{rows}{weekRows}</tbody></table>
    }

    return (
        <React.Fragment>
            {props.label && <div className="label" style={{ paddingLeft: '0px' }}>{props.label}</div>}
            <div style={{ borderBottom: '1px solid gray', padding: '4px', minHeight: '20px', width: '100%' }} onClick={() => { if(date){setSelectedDate(date.clone())}; setShowPart(0); setShowSelection(true) }}>{date && date.format(props.format || props.showTimeSelect ? 'dd DD MMM YYYY HH:mm' : 'dd DD MMM YYYY')}</div>

            <Dialog open={showSelection} onClose={() => { setShowSelection(false) }} aria-labelledby="form-dialog-title" fullScreen={window.innerWidth < 500} className="padding-ios">
                <DialogTitle style={{ padding: '0px' }}>
                    <div style={{ width: '100%', textAlign: 'center', padding: '8px', color: primary ,fontSize: 'x-large'}}>{selectedDate.format(props.showTimeSelect ? 'dd DD MMM YYYY HH:mm' : 'dd DD MMM YYYY')}</div>
                    {props.showTimeSelect && <AppBar position="static">
                        <Tabs centered value={showPart} onChange={(ev, val) => { setShowPart(val) }} aria-label="Kies datum/tijd">
                            <Tab label="Datum" />
                            <Tab label="Tijd" />
                        </Tabs>
                    </AppBar>}
                </DialogTitle>
                <DialogContent style={{ margin: '0px', padding: '0px', minWidth: window.innerWidth < 500 ? '500' : 400 }}>

                    {showPart === 0 && <React.Fragment>
                        <table style={{ width: '100%' }}>
                            <tbody>
                                <tr>
                                    <td><IconButton onClick={() => { setSelectedMonth(selectedMonth.clone().add(-1, 'months')) }}><IconPrevious /></IconButton></td>
                                    <td style={{ textAlign: 'center' }}>{selectedMonth.format('MMM')} {selectedMonth.format('YYYY')}</td>
                                    <td style={{ textAlign: 'right' }}><IconButton onClick={() => { setSelectedMonth(selectedMonth.clone().add(1, 'months')) }}><IconNext /></IconButton></td>
                                </tr>
                            </tbody>
                        </table>
                        <div>{getRows()}</div>
                    </React.Fragment>}

                    {showPart === 1 && <React.Fragment>
                        <table cellPadding={10} cellSpacing={0} style={{ width: '100%' }}>
                            <tbody>
                                <tr>
                                    <td><IconNight style={{ color: 'gray' }} /></td>
                                    {[0, 1, 2, 3, 4, 5].map(hour => {
                                        let style = {};
                                        style.cursor = 'pointer'
                                        style.color = 'gray';
                                        style.textAlign = 'center';
                                        if (hour === selectedDate.hour()) {
                                            style.backgroundColor = primary;
                                            style.color = 'white';
                                        }
                                        return <td key={hour} style={style} onClick={() => { moveToHour(hour) }}>{padLeft(hour, 2)}</td>
                                    })}
                                </tr>
                                <tr>
                                    <td rowSpan={2}><IconDay /></td>
                                    {[6, 7, 8, 9, 10, 11].map(hour => {
                                        let style = {};
                                        style.cursor = 'pointer'
                                        style.textAlign = 'center';
                                        if (hour === selectedDate.hour()) {
                                            style.backgroundColor = primary;
                                            style.color = 'white';
                                        }
                                        return <td key={hour} style={style} onClick={() => { moveToHour(hour) }}>{padLeft(hour, 2)}</td>
                                    })}
                                </tr>
                                <tr>
                                    {[12, 13, 14, 15, 16, 17].map(hour => {
                                        let style = {};
                                        style.cursor = 'pointer'
                                        style.textAlign = 'center';
                                        if (hour === selectedDate.hour()) {
                                            style.backgroundColor = primary;
                                            style.color = 'white';
                                        }
                                        return <td key={hour} style={style} onClick={() => { moveToHour(hour) }}>{padLeft(hour, 2)}</td>
                                    })}
                                </tr>
                                <tr>
                                    <td ><IconNight style={{ color: 'gray' }} /></td>
                                    {[18, 19, 20, 21, 22, 23].map(hour => {
                                        let style = {};
                                        style.cursor = 'pointer'
                                        style.color = 'gray';
                                        style.textAlign = 'center';
                                        if (hour === selectedDate.hour()) {
                                            style.backgroundColor = primary;
                                            style.color = 'white';
                                        }
                                        return <td key={hour} style={style} onClick={() => { moveToHour(hour) }}>{padLeft(hour, 2)}</td>
                                    })}
                                </tr>
                                <tr ><td colSpan={7}>&nbsp;</td></tr>
                                <tr>
                                    <td>Min.</td>
                                    {[0, 5, 10, 15, 20, 25].map((minute) => {
                                        let style = {};
                                        style.cursor = 'pointer';
                                        style.textAlign = 'center';
                                        if (minute === selectedDate.minute()) {
                                            style.backgroundColor = primary;
                                            style.color = 'white';
                                        }
                                        return <td key={minute} style={style} onClick={() => { moveToMinute(minute) }}>:{padLeft(minute, 2)}</td>
                                    })}
                                </tr>
                                <tr>
                                    <td></td>
                                    {[30, 35, 40, 45, 50, 55].map((minute) => {
                                        let style = {};
                                        style.cursor = 'pointer';
                                        style.textAlign = 'center';
                                        if (minute === selectedDate.minute()) {
                                            style.backgroundColor = primary;
                                            style.color = 'white';
                                        }
                                        return <td key={minute} style={style} onClick={() => { moveToMinute(minute) }}>:{padLeft(minute, 2)}</td>
                                    })}
                                </tr>
                                <tr ><td colSpan={7}>&nbsp;</td></tr>
                            </tbody>
                        </table>
                    </React.Fragment>}

                </DialogContent>
                <DialogActions>

                    <Button onClick={() => { setShowSelection(false) }} color="primary">
                        Annuleren
          </Button>
                    <Button onClick={() => { setDate(selectedDate.clone()); setShowSelection(false); props.onChange(selectedDate.toDate()) }} color="primary" variant="contained">
                        OK
          </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
export default withTheme(FormDialog)
