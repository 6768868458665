import React from 'react'
import { TextField, Paper, FormControlLabel, Switch, IconButton, Radio,Divider, Select, MenuItem } from '@material-ui/core'
import IconDelete from '@material-ui/icons/Delete'
import IconUp from '@material-ui/icons/VerticalAlignTop';
import IconDown from '@material-ui/icons/VerticalAlignBottom';
export default function Score({ item, onChange, onDelete, onChangeSort }) {
    const changeItem = (content, attribute) => {
        let newItem = Object.assign({}, item);
        newItem[attribute] = content;
        onChange(newItem);
    }
    const checkBoxes = [];
    const start = item.scoreStart || 0;
    const end = item.scoreEnd || 10;
    for(let i=start;i<=end;i++){
        checkBoxes.push(<FormControlLabel key={i}
        value="top"
        control={<Radio color="primary" disabled style={{padding:'4px'}}/>}
        label={i}
        labelPlacement="top"
        style={{marginLeft:'0px',marginRight:'0px'}}
      />)
    }
    
    return (
        <Paper className="padding" style={{ marginBottom: '6px' }}>
            <TextField fullWidth placeholder="Vraag" value={item.question || ''} onChange={(ev) => { changeItem(ev.target.value, 'question') }} ></TextField>

            <table><tbody><tr>
                <td>
                    <Select id="scoreStart" value={item.scoreStart || 0} onChange={(ev) => { changeItem(ev.target.value, 'scoreStart') }} >
                        {[0, 1].map((option) => { return <MenuItem key={option} value={option}>{option}</MenuItem> })}
                    </Select>
                </td>
                <td>t.m.</td>
                <td>
                    <Select id="scoreEnd" value={item.scoreEnd || 10} onChange={(ev) => { changeItem(ev.target.value, 'scoreEnd') }} >
                        {[2, 3, 4, 5, 6, 7, 8, 9, 10].map((option) => { return <MenuItem key={option} value={option}>{option}</MenuItem> })}
                    </Select>
                </td>
            </tr></tbody></table>

            <table style={{width:'100%'}}><tbody><tr>
                <td style={{width:'20%'}}>
                    <TextField fullWidth placeholder="Label (optioneel)" value={item.scoreStartLabel || ''} onChange={(ev) => { changeItem(ev.target.value, 'scoreStartLabel') }} ></TextField>
                </td>
                <td style={{textAlign:'center'}}>
                    {checkBoxes}
                </td>
                <td style={{width:'20%'}}>
                    <TextField fullWidth placeholder="Label (optioneel)" value={item.scoreEndLabel || ''} onChange={(ev) => { changeItem(ev.target.value, 'scoreEndLabel') }} ></TextField>
                </td>
            </tr></tbody></table>

            <br /><br /><Divider />
            <IconButton onClick={() => { onChangeSort(item, 'up') }}><IconUp /></IconButton>
            <IconButton onClick={() => { onChangeSort(item, 'down') }}><IconDown /></IconButton>
            <div style={{ float: 'right' }}>
                {onDelete && <IconButton onClick={onDelete}><IconDelete /></IconButton>}
                <FormControlLabel labelPlacement="start" control={<Switch checked={item.required || false} value="required" onChange={() => { changeItem(!item.required, 'required') }} />} label="Verplicht" /></div>
            <br style={{ clear: 'both' }} />
        </Paper>
    )
}
